import Store from "@/store/index";

describe("Store", () => {
  describe("Should has all the stores", () => {
    it("Should have info module", () => {
      const has_info_module = Store.hasModule("info");
      expect(has_info_module).toBe(true);
    });
  });
});
